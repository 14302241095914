


















































































































































































































































































































































import { Component, Vue, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";

interface SelectOption {
  value: string | any;
  label: string;
}

@Component({
  components: {}
})
export default class FormularioCultura extends Mixins(FormValidator) {
  private win = window;
  private base = window.location;
  private cerrado = false;
  private formatRut: string = "";
  private dataForm: any = {
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    rut: "",
    fecha_nacimiento: "",
    telefono: "",
    email: "",
    calle: "",
    numero: "",
    talleres: []
  };
  private formattedFecha = "";
  private listaTalleres: any = [];
  private localeCalendar = {
    days: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado"
    ],
    daysShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
    months: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ],
    monthsShort: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic"
    ]
  };

  @Watch("formatRut")
  private formatRutWatch(newValue: string) {
    newValue = newValue.replace(/\./g, "").toUpperCase();
    this.dataForm.rut = newValue;
    newValue = newValue.replace(/-/g, "");
    if (newValue.length > 5) {
      const rut = newValue
        .substring(0, newValue.length - 1)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      const dv = newValue.substring(newValue.length - 1);
      this.formatRut = `${rut}-${dv}`;
    } else {
      this.formatRut = newValue
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
        .toUpperCase();
    }
  }

  @Watch("formattedFecha")
  private edadWatcher(newValue: any) {
    const now = new Date(Date.now());
    if (typeof newValue == "object") {
      let day = newValue.getDate();
      let month = newValue.getMonth() + 1;
      let year = newValue.getFullYear();
      if (month < 10) {
        if (day < 10) {
          newValue = `0${day}/0${month}/${year}`;
        } else {
          newValue = `${day}/0${month}/${year}`;
        }
      } else {
        if (day < 10) {
          newValue = `0${day}/${month}/${year}`;
        } else {
          newValue = `${day}/${month}/${year}`;
        }
      }
    }
    var birthDate: any = "";
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      if (ua.indexOf("chrome") > -1) {
        birthDate = (newValue.substring(6, 10) +
          "-" +
          newValue.substring(3, 5) +
          "-" +
          newValue.substring(0, 2) +
          " 12:00:00") as any;
      } else {
        birthDate = (newValue.substring(6, 10) +
          "-" +
          newValue.substring(3, 5) +
          "-" +
          newValue.substring(0, 2) +
          "T12:00:00") as any;
      }
    } else if (ua.indexOf("mozilla") != -1) {
      //Usa Mozilla
      birthDate = (newValue.substring(6, 10) +
        "-" +
        newValue.substring(3, 5) +
        "-" +
        newValue.substring(0, 2) +
        "T12:00:00") as any;
    }
    if (birthDate && birthDate.length <= 18) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese una fecha en formato válido (DD-MM-AAAA).",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      this.dataForm.fecha_nacimiento = null;
      return;
    }
    let diff;
    if (birthDate instanceof Date) {
      diff = now.getTime() - birthDate.getTime();
      this.dataForm.fecha_nacimiento = birthDate;
    } else {
      diff = now.getTime() - new Date(birthDate).getTime();
      this.dataForm.fecha_nacimiento = new Date(birthDate);
    }
  }

  private mounted() {
    document.title =
      "Formulario Inscripción Talleres 2025 - Ilustre Municipalidad de Punta Arenas";
    this.getTalleres();
  }
  private selectHorario(item: any) {
    let seleccionado = false;
    let index = -1;
    this.dataForm.talleres.map((el, i) => {
      if (el.id == item.id) {
        seleccionado = true;
        index = i;
      }
    });
    if (!seleccionado) {
      if (this.dataForm.talleres.length < 2) {
        this.dataForm.talleres.push(item);
      } else {
        Notify.create({
          color: "negative",
          message: "Haz alcanzado el máximo de talleres por persona (2)",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
      }
    } else {
      if (index >= 0) {
        this.dataForm.talleres.splice(index, 1);
      }
    }
  }
  private selectedBloqueStyle(id) {
    for (let i = 0; i < this.dataForm.talleres.length; i++) {
      if (this.dataForm.talleres[i].id == id) {
        return "color:white; background-color: #02436b;";
      }
    }
    return "color: #02436b;";
  }

  private capitalizeText(texto) {
    texto = texto.toLowerCase(); // Convertimos todo el texto a minúscula

    return texto.replace(/\b\p{L}+/gu, (palabra, indice) => {
      // Siempre capitalizar la primera palabra de la oración
      if (indice === 0 || palabra.length > 3) {
        return palabra.charAt(0).toUpperCase() + palabra.slice(1);
      }
      return palabra; // Dejar en minúscula si tiene 3 o menos caracteres
    });
  }

  get age() {
    // Obtén la fecha a comparar
    var fechaActual = new Date();

    // Resta el año de nacimiento al año actual
    var edad =
      fechaActual.getFullYear() - this.dataForm.fecha_nacimiento.getFullYear();

    // Verifica si aún no ha pasado el día y el mes de cumpleaños
    if (
      fechaActual.getMonth() < this.dataForm.fecha_nacimiento.getMonth() ||
      (fechaActual.getMonth() === this.dataForm.fecha_nacimiento.getMonth() &&
        fechaActual.getDate() < this.dataForm.fecha_nacimiento.getDate())
    ) {
      // Si no ha cumplido años, resta 1 a la edad
      edad--;
    }

    return edad;
  }

  private enviar() {
    if (
      this.dataForm.rut == "" ||
      this.validateRut(this.dataForm.rut) == false
    ) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un Rut válido.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.nombre.trim() == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese su nombre.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.apellido_paterno.trim() == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese su apellido paterno.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.apellido_materno.trim() == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese su apellido materno.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (
      this.dataForm.fecha_nacimiento == null ||
      !this.checkFecha(this.dataForm.fecha_nacimiento)
    ) {
      Notify.create({
        color: "negative",
        message: "Por favor ingrese su fecha de nacimiento",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (
      this.dataForm.fecha_nacimiento == "" ||
      this.dataForm.fecha_nacimiento == null ||
      !this.checkFecha(this.dataForm.fecha_nacimiento)
    ) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese una fecha en formato válido (DD-MM-AAAA)",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      if (!(ua.indexOf("chrome") > -1)) {
        if (this.dataForm.fecha_nacimiento.getFullYear() < 1890) {
          Notify.create({
            color: "negative",
            message:
              "Por favor, ingrese una fecha en formato válido (DD-MM-AAAA)",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        }
      }
    }
    if (this.age < 14) {
      Notify.create({
        color: "negative",
        message: "Para la inscripción debe ser mayor de 14 años.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    if (
      this.dataForm.email.trim() == "" ||
      !this.validEmail(this.dataForm.email)
    ) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un correo válido.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    if (this.dataForm.telefono.trim() == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese su Teléfono.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.telefono.length < 9) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un teléfono válido (9 dígitos).",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    if (this.dataForm.calle.trim() == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese la calle de su dirección.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    if (this.dataForm.numero.trim() == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese la número de su dirección.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    if (this.dataForm.talleres.length == 0) {
      Notify.create({
        color: "negative",
        message: "Por favor, seleccione al menos 1 taller.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    if (this.dataForm.talleres.length > 2) {
      Notify.create({
        color: "negative",
        message: "Solo se puede seleccionar hasta 2 talleres por inscripción.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .post("formulario/talleres", this.dataForm)
      .then((res: any) => {
        this.$q.loading.hide();
        Notify.create({
          color: "positive",
          message: "Se ha enviado correctamente el formulario.",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        this.routerGo("Home");
        return;
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        if (err.response.data.message == "REGISTRADO") {
          Notify.create({
            color: "negative",
            message: "El RUT ingresado ya cuenta con un registro.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        } else {
          Notify.create({
            color: "negative",
            message: "Ha ocurrido un error, por favor intente nuevamente.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        }
      });
  }

  private getTalleres() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("formulario/talleres")
      .then((res: any) => {
        this.listaTalleres = res.data;
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.listaTalleres = [];
        this.$q.loading.hide();
      });
  }

  private checkFecha(d: any) {
    if (Object.prototype.toString.call(d) === "[object Date]") {
      // it is a date
      if (isNaN(d.getTime())) {
        // d.valueOf() could also work
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }
  private validEmail(email: string) {
    // eslint-disable-next-line
    let emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(email) === true) {
      return true;
    } else {
      return false;
    }
  }
  public validateRut(newValue: string) {
    let texto = newValue;
    let tmpstr = "";
    for (let i = 0; i < texto.length; i++)
      if (
        texto.charAt(i) != " " &&
        texto.charAt(i) != "." &&
        texto.charAt(i) != "-"
      )
        tmpstr = tmpstr + texto.charAt(i);
    texto = tmpstr;
    const largo = texto.length;
    if (largo < 2) {
      return false;
    }
    for (let i = 0; i < largo; i++) {
      if (
        texto.charAt(i) != "0" &&
        texto.charAt(i) != "1" &&
        texto.charAt(i) != "2" &&
        texto.charAt(i) != "3" &&
        texto.charAt(i) != "4" &&
        texto.charAt(i) != "5" &&
        texto.charAt(i) != "6" &&
        texto.charAt(i) != "7" &&
        texto.charAt(i) != "8" &&
        texto.charAt(i) != "9" &&
        texto.charAt(i) != "k" &&
        texto.charAt(i) != "K"
      ) {
        return false;
      }
    }
    let invertido = "";
    for (let i = largo - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + texto.charAt(i);
    let dtexto = "";
    dtexto = dtexto + invertido.charAt(0);
    dtexto = dtexto + "-";
    let cnt = 0;
    for (let i = 1, j = 2; i < largo; i++, j++) {
      //alert("i=[" + i + "] j=[" + j +"]" );
      if (cnt == 3) {
        dtexto = dtexto + ".";
        j++;
        dtexto = dtexto + invertido.charAt(i);
        cnt = 1;
      } else {
        dtexto = dtexto + invertido.charAt(i);
        cnt++;
      }
    }
    invertido = "";
    for (let i = dtexto.length - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + dtexto.charAt(i);

    if (this.revisarDigito2(texto)) {
      return true;
    }
    return false;
  }
  public revisarDigito(dvr: string) {
    const dv = dvr + "";
    if (
      dv != "0" &&
      dv != "1" &&
      dv != "2" &&
      dv != "3" &&
      dv != "4" &&
      dv != "5" &&
      dv != "6" &&
      dv != "7" &&
      dv != "8" &&
      dv != "9" &&
      dv != "k" &&
      dv != "K"
    ) {
      return false;
    }
    return true;
  }
  public revisarDigito2(crut: string) {
    const largo = crut.length;
    if (largo < 2) {
      return false;
    }
    let rut = "";
    if (largo > 2) rut = crut.substring(0, largo - 1);
    else rut = crut.charAt(0);
    const dv = crut.charAt(largo - 1);
    this.revisarDigito(dv);
    if (rut == null || dv == null) return 0;
    let dvr = "0";
    let suma = 0;
    let mul = 2;
    for (let i = rut.length - 1; i >= 0; i--) {
      suma = suma + parseInt(rut.charAt(i), 10) * mul;
      if (mul == 7) mul = 2;
      else mul++;
    }
    const res = suma % 11;
    if (res == 1) dvr = "k";
    else if (res == 0) dvr = "0";
    else {
      const dvi = 11 - res;
      dvr = dvi + "";
    }
    if (dvr != dv.toLowerCase()) {
      return false;
    }
    return true;
  }
}
